<script setup lang="ts">
import {PopoverContent, PopoverRoot, PopoverTrigger} from 'radix-vue'

const {signOut} = useAuth();

</script>

<template>
  <PopoverRoot>
    <PopoverTrigger class="profile-popover-button">
      <UIToolbarToggleButton>
        <Icon name="lucide:user"/>
      </UIToolbarToggleButton>
    </PopoverTrigger>

    <PopoverContent class="profile-popover">
      <div class="profile-popover-balance-overview">
        <UIButton full-width @click="signOut({callbackUrl:'/auth/login'})">Logout</UIButton>
      </div>
    </PopoverContent>
  </PopoverRoot>
</template>

<style>
.profile-popover-container {
  position: relative;
  height: 36px;
}

.profile-popover-button {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
}

.profile-popover {
  position: absolute;
  width: 320px;
  z-index: 10;
  right: 0;
  background: var(--background);
  padding: var(--tw-size-4);
  margin-block-start: var(--tw-size-1);;
  border: 1px solid var(--border-color);
  border-radius: var(--tw-border-radius-md);
}

.profile-popover-balance-overview {
  display: flex;
  gap: var(--tw-size-2);
  flex-direction: column;
  align-items: flex-start;
  text-wrap: nowrap;
}
</style>
